<template>
<div class="container">

    <Header :title="$t('members.members')" />
    
    <div v-if="this.users">
    <SearchBar :text="$t('members.find')" v-model="search" /><br/>
    <MembersList 
        v-for="(user,index) in filterUsers" 
        :key="user.id" 
        :user="user" 
        :me="auth" 
        @del="xtachUser({action:'detach',pid:auth.project.id,uid:$event,index:index})"    
        @accept="xtachUser({action:'accept',pid:auth.project.id,uid:$event,func:'getMembers'})"
    />
    </div>

    <Request />

</div>
</template>

<script>
import SearchBar from "@/views/BaseComponents/SearchBar.vue";
import MembersList from "@/views/MembersComponents/MembersList.vue";
import Request from "@/views/BaseComponents/Request.vue";

export default {
    name: 'Members',
    components: { SearchBar, MembersList, Request },
    async created() { 
        await this.getMembers(); 
        this.notify = ['Members',0];
    },
    data() {return{
    search: '',
    users: null
    }},
    computed: {
        filterUsers() {
            if(!this.users) return false;
            return this.users.filter(user => {
                return user.name.toLowerCase().includes(this.search.toLowerCase()) ||
                        user.role.toLowerCase().includes(this.search.toLowerCase());
            });
        },
    },
    methods: {
        async getMembers() { 
            let data = await this.axon('get','projects/'+this.auth.project.id); 
            if(data && data.users) this.users = data.users;
        },
    }

    
}
</script>