<template>
<div>
    <div class="user-profile overflow-auto mb-3 text-left pointer" @click="info()">
        <img :src="_user.avatar ? getAvatar(user.id) : default_avatar" class="avatar mr-2 float-left" alt="" />
        <div>
            <div class="author"><strong>{{ _user.name }}</strong> </div>
            <div class="date text-small text-muted">{{ subtext_show }}</div>
        </div>
    </div>
    <hr v-if="line" />
</div>
</template>

<style scoped>
    .avatar { 
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #c0c0c0;
    }
</style>

<script>
import UserBox from '@/views/PostComponents/UserBox.vue';
export default {
    props: {
        user:   { type: Object },
        subtext:{ type: String },
        line:   { type: Boolean } 
    },
    data() {return{ default_avatar: require("@/assets/avatar-default.png") }
    },
    computed: {
        _user() {
            if(this.user) return this.user;
            else return {
                id: 0, avatar: null, 
                name: this.$t('profile.deleted_name')
            }
        },
        subtext_show() { 
            if(this.subtext) return this.subtext; 
            else if(this.user.role) return this.$t('role.'+this.user.role); 
            else return null; }
    },
    methods: {
        info() {
            let text = this.$createElement(UserBox,{props: { user: this._user }});
            this.$bvModal.msgBoxOk(text, { centered: true });
        },

    }
    
}
</script>
