<template>
    <div class="row justify-content-center" v-if="user">
        
        <div class="col-lg-8 mx-auto">

            <!-- detach -->
            <b-button 
                v-if="me.project.role=='admin' && me.id!=user.id"
                variant="danger"
                size="sm"
                class="ml-1 float-right"
                @click="$emit('del',user.id)"
            ><b-icon icon="x" />
            </b-button>

            <!-- accept -->
            <b-button 
                v-if="me.project.role=='admin' && user.role=='request'"
                variant="primary"
                size="sm"
                class="ml-1 float-right"
                @click="$emit('accept',user.id)"
            ><b-icon icon="check" />
            </b-button>

            <UserInfo :user="user" line />

        </div>

    </div>
</template>

<script>
import UserInfo from "@/views/PostComponents/UserInfo.vue";
export default {
    components: { UserInfo },
    props: ['user','me']
}
</script>