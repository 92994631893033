<template>
<div class="row text-center">

    <!-- avatar image wrapper -->
    <div class="col-lg-8 col-md-8 col-sm-8 text-center mx-auto">
        <div class="avatar my-3 d-block mx-auto text-center">
            <img :src="user.avatar ? getAvatar(user.id) : default_avatar" alt="" class="img-fluid mx-auto" />
        </div>  

        <h4 class="mb-0">{{ user.name }}</h4>
        <div class="text-muted text-small" v-text="personal"></div>

        <!-- data -->
        <div class="mt-2">
            <span>{{ $t('profile.email') }}: <a :href="'mailto:'+user.email">{{ user.email }}</a></span><br/>
            <span v-if="user.website">{{ $t('profile.website') }}: <a :href="'http://'+user.website">{{ user.website }}</a></span><br/>
            <span v-if="user.phone">{{ $t('profile.phone') }}: <a :href="'tel:'+user.phone">{{ user.phone }}</a></span>
        </div>

    </div>

</div>    
</template>

<style scoped>
.avatar {
    border: 5px dotted #d0d0d0;
    width: 250px;
    height: 250px;
    border-radius: 125px;
    overflow: hidden;
}
.avatar img {
    height: 100%;
    width: 100%;
}
</style>

<script>
export default {
    props: ['user'],
    data() {return{
        default_avatar: require("@/assets/avatar-default.png"),
    }},
    computed: {
        personal() {
            let personal = [];
            if(this.user.birth) personal.push(this.user.birth);
            if(this.user.city) personal.push(this.user.city);
            if(this.user.country) personal.push(this.user.country);
            return personal.join(', ');
        }
    },
}
</script>