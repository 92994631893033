<template>
    <div class="bg-light text-muted border p-2 mt-3 mb-2 row mx-0 justify-content-center">
        <div class="col-xs-10 col-10 mx-0 px-0">
                <b-input 
                    id="search" 
                    type="search" 
                    :placeholder="text" 
                    :value="input"
                    @input="$emit('input', $event)" ref="input"
                    @keyup.enter="$emit('click', input)"
                    />
        </div>
        <div class="col-xs-2 px-0 text-left">
            <b-button variant="light" @click="$emit('click', input)"><b-icon icon="search" /></b-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: { type: String, default: "Search…"},
        input: String,
    },
}
</script>
